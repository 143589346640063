<template>
  <div class="search">
    <v-row class="mt-3 mb-3">
      <v-btn color="blue" variant="tonal" @click="onNewContact">New Contact</v-btn>
      <v-spacer />
      <CustomCheckBox id="toggleSinglePersonSearch" @on-checked="toggleSinglePersonSearch" :value="singlePersonSearch"
        label="Search for a single person" />
    </v-row>
    <v-row>
      <CustomInput label="Search" :value="searchText" @on-input="search" :error="searchError" :has-reset="hasReset"
        @on-reset="reset" />
    </v-row>
    <v-row class="count d-flex justify-end">
      Found: {{ found() }} of {{ total() }}
    </v-row>

    <v-row>
      <v-data-table-server :headers="headers" :items="items" :sort-by="sortBy" @update:sort-by="customSort"
        :loading="loading" hide-default-footer fixed-header height="calc(80vh - 80px)" item-value="id" hover
        @click:row="onOpen" :itemsLength="found()" multi-sort hide-actions>
        <template #[`item.male`]="{ item }">
          <v-icon v-if="item.male == true">mdi-face-man-outline</v-icon>
          <v-icon v-if="item.male == false">mdi-face-woman-outline</v-icon>
        </template>
      </v-data-table-server>
    </v-row>


    <!-- PersonView -->
    <div v-if="displayDetails">
      <Suspense>
        <template #default>
          <PersonView :token="token" :person_id="personId" @on-close="onClose" @on-add="onAdd" @on-update="onUpdate"
            @on-delete="onDelete" :read_only="readOnly" />
        </template>
        <template #fallback>
          <v-dialog v-model="loadDialog" :scrim="false" persistent width="auto">
            <v-card color="primary">
              <v-card-text>
                Loading...
                <v-progress-linear indeterminate color="white" class="mb-0" />
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
      </Suspense>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, defineProps } from 'vue'
import CustomCheckBox from './CustomCheckBox.vue'
import CustomInput from '@/components/CustomInput.vue'
import PersonView from './PersonView.vue'
import SearchParser from '@/classes/SearchParser'

import { ContactsApi, ISort } from "@/classes/ContactsApi"
import { Person } from '@/classes/Person'
import { IPerson } from '@/interfaces/IPerson'

interface Props {
  token: string
}

const props = defineProps<Props>()

const personId = ref<number>(0)
const displayDetails = ref<boolean>(false)
const readOnly = ref<boolean>(true)
const loadDialog = ref<boolean>(true)

const singlePersonSearch = ref<boolean>(true)
const loading = ref<boolean>(true)
const hasReset = ref<boolean>(true)
const searchText = ref<string>('')
const searchError = ref<string>('')

const headers = [
  { title: "", key: "male", width: "5px" },
  { title: "id", key: "id" },
  { title: "firstname", key: "firstname" },
  { title: "lastname", key: "lastname" },
  { title: "street", key: "street" },
  { title: "phone", key: "phone" },
  { title: "email", key: "email" },
  { title: "city", key: "city" }
]

const sortBy = ref<ISort[]>([
  { key: 'lastname', order: 'asc' },
  { key: 'firstname', order: 'asc' }
])

const items = ref<IPerson[]>()

let contacts = new ContactsApi<IPerson[]>({
  command: "contacts.get_persons",
  token: props.token,
  limit: 0,
  offset: 0,
  columns: [
    "male",
    "id",
    "firstname",
    "lastname",
    "street",
    "phone",
    "email",
    "city"
  ],
  sort: sortBy.value,
  filter: [],
})

const found = (): number => {
  return items.value !== undefined ? items.value.length : 0
}

const total = (): number => {
  return contacts.data?.total
}

const onOpen = (event, target): void => {
  personId.value = target.item.id
  displayDetails.value = true;
}

const customSort = (newSortBy) => {
  contacts.payload.sort = newSortBy
  sortBy.value = newSortBy
  localStorage.setItem('sortBy', JSON.stringify(newSortBy))
  search(searchText.value)
}

const onNewContact = () => {
  displayDetails.value = true
  readOnly.value = false
  personId.value = 0
}

async function onClose() {
  displayDetails.value = false;
  readOnly.value = true;
}

async function onAdd(person: Person) {
  if (person) {
    await person.Add().then(() => {
      search(searchText.value)
    })
  }
  displayDetails.value = false
}

async function onUpdate(person: Person) {
  if (person) {
    await person.Update().then(() => {
      search(searchText.value)
    })
  }
  displayDetails.value = false
}

async function onDelete(person: Person) {
  if (person) {
    await person.Delete().then(() => {
      search(searchText.value)
    })
  }
  displayDetails.value = false
}

const search = (value: string) => {
  const parser = new SearchParser(value, singlePersonSearch.value)
  if (!(searchError.value = parser.error())) {
    contacts.payload.filter = parser.generateFilter()
    loading.value = true
    contacts.fetch().then(() => {
      items.value = contacts.result
      loading.value = false
    })
  }
  searchText.value = parser.searchText
}

const toggleSinglePersonSearch = () => {
  singlePersonSearch.value = singlePersonSearch.value ? false : true
  search(searchText.value)
}

const reset = () => {
  searchText.value = ""
  search(searchText.value)
}


onMounted(() => {

  if ("sortBy" in localStorage) {
    const loadedSortBy = JSON.parse(localStorage.sortBy) as ISort[]
    sortBy.value = loadedSortBy
  }
  contacts.payload.sort = sortBy.value
  contacts.fetch().then(() => {
    items.value = contacts.result
    loading.value = false
  })
})

</script>

<style lang="scss">
.border {
  border: 1px solid red;
}

.count {
  font-weight: bold;
  font-style: italic;
  padding-right: 0.5em;
}
</style>
