<template>
  <v-container class="container-style">
    <v-expansion-panels v-model="panel">
      <v-expansion-panel value="buttons">
        <v-expansion-panel-title>
          <template v-slot:default="{ expanded }">
            <v-row no-gutters>
              <v-col class="d-flex justify-start" cols="4">
                <span v-if="!expanded" key="0">
                  Add connection
                </span>
                <span v-if="expanded" key="0">
                  Choose Connection type
                </span>
              </v-col>
              <v-col class="text-grey" cols="8">
                <v-fade-transition leave-absolute>
                </v-fade-transition>
              </v-col>
            </v-row>
          </template>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row class="ma-2" no-gutters v-for="type in types" :key="type.id">
            <v-col>
              <v-btn width="100%" class="pa-2" @click="onAdd(type)">{{ type.name }}</v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script setup lang="ts">
import { ref, onMounted, defineEmits, defineProps } from 'vue'
import { ContactsApi } from '@/classes/ContactsApi';
import { IConnectionType } from '@/interfaces/IConnectionType';

const loading = ref<boolean>(true)
const panel = ref<[]>([])

interface Props {
  token: string
}

const props = defineProps<Props>()

const types = ref<IConnectionType[]>()

const emits = defineEmits(["onAdd"])

let connection_types = new ContactsApi<IConnectionType[]>({
  command: 'contacts.get_connection_types',
  token: props.token,
  sort: [{ key: 'name', order: 'ASC' }]
})

const onAdd = (type: IConnectionType) => {
  console.log("Type: ", type)
  panel.value = []
  emits("onAdd", type)
}

const fetchTypes = () => {
  if (!types.value || types.value?.length === 0) {
    loading.value = true
    connection_types.fetch().then(() => {
      types.value = connection_types.result
      loading.value = false
    })
  }
}

onMounted(() => {
  fetchTypes()
})

</script>

<style lang="scss">
.container-style {
  width: 50%;
  margin: initial;
  padding: initial;
  margin-bottom: 2em;

}
</style>
