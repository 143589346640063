<template>
  <div class="day-of-birth">
    <v-text-field @click="onShow" label="Day Of Birth" :model-value="formattedDate()" :readonly="true" />
    <v-container class="day-of-birth-date-picker" v-if="show">
      <v-date-picker v-model="dayOfBirth" @update:model-value="onUpdate" ref="picker" elevation="10"
        input-placeholder="yyyy-mm-dd" view-mode="year" hide-header />
    </v-container>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, withDefaults, onMounted, defineEmits } from 'vue';
import { VDatePicker } from 'vuetify/lib/components/index.mjs';

const emits = defineEmits(["dayOfBirthSelected"])

interface Props {
  readOnly: boolean
  dayOfBirth?: Date
}

const props = withDefaults(defineProps<Props>(), {
  readOnly: true
})

const show = ref<boolean>(false)
const dayOfBirth = ref<Date>()
const picker = ref<VDatePicker>()

const formattedDate = (): string => {
  const date = dayOfBirth.value as Date
  if (date) {
    return "" + date.getFullYear() + "-" + ((date.getMonth() + 1) > 9 ? '' : '0') + (date.getMonth() + 1) + "-" + (date.getDate() > 9 ? '' : '0') + date.getDate()
  }
  return ""
}

const onUpdate = (date: Date) => {
  if (date) {
    // dayOfBirth.value = "" + date.getFullYear() + "-" + ((date.getMonth() + 1) > 9 ? '' : '0') + (date.getMonth() + 1) + "-" + (date.getDate() > 9 ? '' : '0') + date.getDate()
    dayOfBirth.value = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    emits("dayOfBirthSelected", dayOfBirth.value)
  }
  show.value = false
}

const onShow = () => {
  if (!props.readOnly.valueOf()) {
    show.value = show.value ? false : true
  }
}

onMounted(() => {
  if (props.dayOfBirth) {
    dayOfBirth.value = new Date(props.dayOfBirth)
  }
})

</script>

<style lang="scss">
.day-of-birth {
  position: relative;

  .day-of-birth-date-picker {
    bottom: -10em;
    left: 10em;
    z-index: 100;
    position: absolute;
    background: rgba(var(--v-background))
  }
}
</style>
