<template>
  <v-combobox v-model="city" @update:search="onInput" @update:model-value="onCitySelected" :label="label"
    :item-value="nameValue ? 'name' : 'zipcode'" :items=suggestions :menu-icon="readOnly ? '' : '$dropdown'"
    :item-title="nameValue ? 'name' : 'zipcode'" :loading="loading" return-object :readonly="readOnly">
    <template v-slot:item="{ props, item }">
      <v-list-item v-bind="props" :title="nameValue ? item.raw.name : item.raw.zipcode"
        :subtitle="nameValue ? item.raw.zipcode : item.raw.name" />
    </template>
  </v-combobox>
</template>

<script setup lang="ts">
import { ICity } from '@/interfaces/ICity';
import { defineProps, defineEmits, ref, withDefaults, onMounted, watch } from 'vue';
import { ContactsApi } from '@/classes/ContactsApi';
import { IFilter } from '@/interfaces/IGetApi';

const emits = defineEmits(["onCitySelected"])

interface Props {
  label: string,
  nameValue: boolean,
  city?: ICity,
  readOnly?: boolean
  token: string
}

const city = ref<ICity>()

const props = withDefaults(defineProps<Props>(), {
  label: 'No label',
  nameValue: true,
  readOnly: true
})

let Districts = new ContactsApi<ICity[]>({
  command: 'contacts.get_districts',
  token: props.token,
  sort: [
    {
      key: props.nameValue ? 'name' : 'zipcode',
      order: 'ASC'
    },
    {
      key: props.nameValue ? 'zipcode' : 'name',
      order: 'ASC'
    }],
})

const suggestions = ref<ICity[]>()
const loading = ref<boolean>(false)

const onCitySelected = (value: unknown) => {
  if (typeof value === "string") {
    if (props.nameValue) {
      emits('onCitySelected', { name: value })
    } else {
      emits('onCitySelected', { zipcode: value })
    }
  } else {
    emits('onCitySelected', value)
  }
}

const onInput = (value: string) => {
  loading.value = true
  if (value.length > 0) {
    let filter: IFilter[] = [{
      column: props.nameValue ? 'name' : 'zipcode',
      operator: 'ilike',
      value: value + '%'
    }]
    Districts.set_filter(filter)
    Districts.fetch().then(() => {
      suggestions.value = Districts.result
      loading.value = false
    })
  } else {
    suggestions.value = []
    loading.value = false
  }
}

onMounted(() => {
  if (props.city?.id !== undefined) {
    city.value = props.nameValue ? props.city.name : props.city.zipcode
  }
})

watch(props, (_, newValue) => {
  if (newValue.city?.id !== undefined) {
    city.value = newValue.city
  }
})

</script>

<style lang="scss" scoped></style>
