<template>
  <v-autocomplete @update:search="onInput" v-model="country" label="Country" item-value="name" item-title="name"
    :menu-icon="readOnly ? '' : '$dropdown'" :loading="loading" :items=suggestions :readonly="readOnly"
    @update:model-value="onCountrySelected" return-object>
  </v-autocomplete>
</template>

<script setup lang="ts">
import { ContactsApi } from '@/classes/ContactsApi';
import { ICountry } from '@/interfaces/ICountry';
import { defineProps, defineEmits, withDefaults, ref, onMounted, watch } from 'vue'
import { IFilter } from '@/interfaces/IContactsApi';

const emits = defineEmits(["onCountrySelected"])


interface Props {
  label: string,
  country?: ICountry,
  readOnly: boolean,
  token: string
}

const country = ref<ICountry>()

const props = withDefaults(defineProps<Props>(), {
  label: 'Country',
  readOnly: true
})

let Countries = new ContactsApi<ICountry[]>({
  command: 'contacts.get_countries',
  token: props.token,
  sort: [{ key: 'name', order: 'ASC' }],
  limit: 10
})

const suggestions = ref<ICountry[]>()
const loading = ref<boolean>(false)

const onInput = (value: string) => {
  loading.value = true
  if (value.length > 0) {
    let filter: IFilter[] = [{
      column: 'name',
      operator: 'ilike',
      value: value + '%'
    }]
    Countries.set_filter(filter)
    Countries.fetch().then(() => {
      suggestions.value = Countries.result
      loading.value = false
    })
  } else {
    suggestions.value = []
    loading.value = false
  }
}

const onCountrySelected = (c: ICountry) => {
  emits("onCountrySelected", c)
}

onMounted(() => {
  if (props.country?.code !== undefined) {
    country.value = props.country
  }
})

watch(props, (_, newValue) => {
  if (newValue.country?.code !== undefined) {
    country.value = newValue.country
  }
})
</script>

<style lang="scss" scoped></style>
