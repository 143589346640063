<template>
  <v-row align="center">
    <v-col align="center">
      <v-card v-if="tags !== undefined">
        <v-card-title align="left">
          Tags
        </v-card-title>
        <v-card-text>
          <v-text-field v-if="tags.nickname !== undefined" label="Nickname" v-model="nickname" :readonly="readonly" />
        </v-card-text>
      </v-card>
      <v-card v-if="tags === undefined">
        Has no tags
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { ref, defineProps } from 'vue'
import { IPersonTags } from '@/interfaces/IPersonTags'


interface Props {
  tags?: IPersonTags
  readonly: boolean
}

const props = defineProps<Props>()

const nickname = ref<string>(props.tags?.nickname)

</script>
