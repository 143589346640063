import {
  PublicClientApplication,
  type AccountInfo,
  type RedirectRequest,
} from "@azure/msal-browser";

import { reactive } from "vue";

export const msalConfig = {
  auth: {
    clientId: "3b2c05b8-e818-45b7-a195-5e77764889d5",
    authority:
      "https://login.microsoftonline.com/c54020f5-4206-48d4-affe-e73196defa43",
    redirectUri: window.location.origin, // Replace with your actual redirect URI
    postLogoutUri: window.location.origin,
  },
  cache: {
    cacheLocation: "sessionStorage", //  This configures where you cache will be stored
    storeAuthStateInCookie: false,
  },
};

export const graphScopes: RedirectRequest = {
  scopes: ["user.read", "openid", "profile", "group.Read.All"],
};

export const state = reactive({
  isAuthenticated: false,
  user: null as AccountInfo | null,
});

export const myMSALObj = new PublicClientApplication(msalConfig);
