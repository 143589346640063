<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent width="80%">
      <v-tabs v-model="selectedTab" align-tabs="center" bg-color="indigo" fixed-tabs>
        <v-tab value="general">
          General
        </v-tab>
        <v-tab value="tags">
          Tags
        </v-tab>
        <v-tab value="member">
          Member
        </v-tab>
      </v-tabs>
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              <span class="person-id">{{ person?.id }}</span>
            </v-col>
            <v-col align-self="end" style="text-align:right">
              <v-icon class="edit-mode" :color="!readOnly ? 'blue' : undefined" :class="readOnly ? '' : 'edit'"
                @click="onEdit">mdi-account-edit</v-icon>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-tabs-window v-model="selectedTab">
              <v-tabs-window-item value="general">
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <div class="firstname">
                      <v-text-field label="First Name" v-model="person.firstname" :readonly="readOnly" required />
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field label="Middle Name" v-model="person.middlename" :readonly="readOnly" />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field label="Last Name" v-model="person.lastname" :readonly="readOnly" />
                  </v-col>
                  <v-col cols="12">
                    <StreetInput :token="token" label="Street" :address="person.address" :read-only="readOnly"
                      @on-value-updated="streetUpdated" />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <DistrictInput :token="token" label="ZipCode" @on-city-selected="onSelectedCity" :name-value="false"
                      :city="person?.address?.city" :read-only="readOnly" />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <DistrictInput :token="token" label="City" @on-city-selected="onSelectedCity" :name-value="true"
                      :city="person?.address?.city" :read-only="readOnly" />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <CountryInput :token="token" label="Country" @on-country-selected="onCountrySelected"
                      :country="person?.address?.city?.country" :read-only="readOnly" />
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <DayOfBirthInput :day-of-birth="dayOfBirth()" :read-only="readOnly"
                      @day-of-birth-selected="dayOfBirthSelected" />
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <SelectGender :male="person.male" :read-only="readOnly" @gender-selected="genderSelected" />
                  </v-col>
                  <v-col cols="12">
                    <ConnectionList :token="token" :connections="person?.connections as IConnectionComponent[]"
                      @new-connection="onNewConnection" :read-only="readOnly" />
                  </v-col>
                </v-row>
              </v-tabs-window-item>
              <v-tabs-window-item value="tags">
                <PersonTags :tags="person.tags" :readonly="readOnly" />
              </v-tabs-window-item>
              <v-tabs-window-item value="member">
                <MemberInfo :person_id="person_id" :token="token" :readonly="readOnly" />
              </v-tabs-window-item>
            </v-tabs-window>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="!readOnly && person.id > 0" prepend-icon="mdi-trash-can" variant="tonal" color="red"
            @click="deleteDialog = true">Delete</v-btn>
          <v-spacer />
          <v-btn color="blue-darken-1" variant="text" @click="onCancel">Close</v-btn>
          <v-btn v-if="!readOnly" color="blue-darken-1" variant="text" @click="onSave">{{ saveButtonText()
            }}</v-btn>
        </v-card-actions>
      </v-card>
      <v-dialog width="auto" v-model="deleteDialog">
        <v-card title="Delete Contact">
          <template v-slot:text>
            Are you sure you want to delete:
            <span class="name">{{ person.firstname }} {{ person.lastname }}</span> with the id
            <span class="id">{{ person.id }}</span>
          </template>
          <v-card-actions>
            <v-spacer />
            <v-btn class="ma-2" color="red" text="Yes" variant="tonal" @click="yesDelete" />
            <v-btn class="ma-2" color="green" text="No" variant="tonal" @click="deleteDialog = false" />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
  </v-row>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits, onMounted } from 'vue'
import StreetInput from './StreetInput.vue'
import DistrictInput from './DistrictInput.vue'
import CountryInput from './CountryInput.vue'
import DayOfBirthInput from './DayOfBirthInput.vue'
import SelectGender from './SelectGender.vue'
import ConnectionList from './ConnectionList.vue'
import MemberInfo from './MemberInfo.vue'
import PersonTags from './PersonTags.vue'
import { ContactsApi } from '@/classes/ContactsApi';
import { IConnectionComponent } from '@/interfaces/IConnectionComponent'
import { IConnectionType } from '@/interfaces/IConnectionType'
import { IConnection } from '@/interfaces/IConnection'
import { IConnectionLocation } from '@/interfaces/IConnectionLocation'
import { IAddress } from '@/interfaces/IAddress'
import { ICity } from '@/interfaces/ICity'
import { ICountry } from '@/interfaces/ICountry'
import Person from '@/classes/Person'
import { IPerson } from '@/interfaces/IPerson'

const emits = defineEmits(["onClose", "onUpdate", "onAdd", "onDelete"])
const dialog = ref<boolean>(true)
const deleteDialog = ref<boolean>(false)
const selectedTab = ref<string>("general")

interface Props {
  person_id: number
  read_only: boolean
  token: string
}


const props = defineProps<Props>()

const person = ref<Person>(new Person({
  id: 0,
  firstname: '',
}, props.token))


const readOnly = ref<boolean>(true)

const saveButtonText = (): string => {
  if (person.value.id === undefined || person.value.id === 0) {
    return "Add"
  }
  return "Save"
}

const onSave = () => {
  if (person.value.id === undefined || person.value.id === 0) {
    emits("onAdd", person.value)
  } else {
    emits("onUpdate", person.value)
  }
}

const onCancel = () => {
  emits('onClose')
}

const onEdit = () => {
  readOnly.value = readOnly.value ? false : true;
}

const yesDelete = () => {
  deleteDialog.value = false
  emits('onDelete', person.value)
}

const onNewConnection = (connectionType: IConnectionType) => {
  let connection = {} as IConnection
  let connectionLocation = {} as IConnectionLocation

  if (connectionType.id === 1) {
    // Set Default location for a phone (1) to Home
    connectionLocation.id = 1
    connectionLocation.name = "Home"
  } else {
    // set Default for other types to private
    connectionLocation.id = 2
    connectionLocation.name = "Private"
  }
  connection.connection_type = connectionType
  connection.connection_location = connectionLocation
  connection.primary = firstOfType(connectionType)
  connection.person_id = person.value?.id || 0
  connection.delete = false;
  connection.connection = ''
  person.value?.connections?.unshift(connection)
}

const firstOfType = (connectionType: IConnectionType): boolean => {
  const found = person.value?.connections?.find((c) => c.connection_type.id === connectionType.id)
  return found === undefined ? true : false
}

let contacts = new ContactsApi<IPerson>({
  command: 'contacts.get_person',
  token: props.token,
  primary_key: "id",
  value: props.person_id.toString()
})

await contacts.fetch().then(() => {
  person.value = new Person(contacts.result as IPerson, props.token)
})

const streetUpdated = (address: IAddress) => {
  if (person.value && address) {
    if (address.id === undefined) {
      person.value.address.id = 0
      person.value.address.residents = []
      person.value.address.street = address.street
    } else {
      person.value.address = address
    }
  }
}

const onSelectedCity = (city: ICity) => {
  if (person.value && person.value.address && city) {
    if (city.id === undefined) {
      person.value.address.city.id = 0
      if (city.zipcode !== undefined) {
        person.value.address.city.zipcode = city.zipcode
        if (person.value.address.city.name === undefined) {
          person.value.address.city.name = ""
        }
      }
      if (city.name !== undefined) {
        person.value.address.city.name = city.name
      }
      if (city.zipcode !== undefined && person.value.address.city.zipcode !== "") {
        person.value.address.city.zipcode = city.zipcode
      }
    } else {
      person.value.address.city = city
    }
  }
}

const onCountrySelected = (country: ICountry) => {
  if (person.value && person.value.address && country) {
    person.value.address.city.country = country
  }
}

const genderSelected = (male: boolean) => {
  if (person.value) {
    person.value.male = male
  }
}

const dayOfBirth = (): Date => {
  if (person.value.day_of_birth) {
    return new Date(person.value.day_of_birth)
  }
  return new Date()
}

const dayOfBirthSelected = (dayOfBirth: Date) => {
  if (person.value) {
    person.value.day_of_birth = dayOfBirth
  }
}

onMounted(() => {
  readOnly.value = props.read_only
})

</script>

<style lang="scss" scoped>
.person-id {
  font-size: 10px;
  font-style: italic;
  color: primary;
  opacity: 0.2;
}

.edit-mode {
  cursor: pointer;

  &:hover {
    color: blue;
  }
}
</style>
