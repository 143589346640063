<template>
  <v-app>
    <v-layout class="rounded rounded-md">
      <v-app-bar class="bar" title="Pingstkyrkan i Lund">
        <div v-if="state.isAuthenticated">
          <span class="login-name">{{ state.user.name }}</span>
          <v-btn @click="handleLogout" icon="mdi-logout"></v-btn>
        </div>
        <div v-else>
          <v-btn color="primary" variant="flat" @click="handleLogin">Log In</v-btn>
        </div>
        <div class="dark-mode d-flex justify-end">
          <v-switch class="ml-2" color="primary" :model-value="darkMode" hide-details @change="toggleDarkMode"
            :label="displayModeText()">
          </v-switch>
        </div>
      </v-app-bar>
      <v-main class="align-center justify-center main">
        <Search :token="state.user.idToken" v-if="state.isAuthenticated && selectedMenu === 'search'" />
      </v-main>
    </v-layout>
  </v-app>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useTheme } from 'vuetify'
import { useAuth } from './config/useAuth'
import { myMSALObj, state } from './config/msalConfig'

import Search from '@/components/SearchView.vue'

const selectedMenu = ref('search')
const darkMode = ref<boolean>(true)
const displayMode = ref<string>(localStorage.displayMode || 'dark')
const theme = useTheme()

const { login, logout, handleRedirect } = useAuth()

const handleLogin = async () => {
  await login()
}

const handleLogout = () => {
  logout()
}

const initialize = async () => {
  try {
    await myMSALObj.initialize()
  } catch (error) {
    console.log('Initialization error', error)
  }
}

const toggleDarkMode = () => {
  darkMode.value = !darkMode.value
  displayMode.value = darkMode.value ? 'dark' : 'light'
  theme.global.name.value = displayMode.value
  localStorage.setItem('displayMode', displayMode.value)
}

const displayModeText = () => {
  return darkMode.value ? 'light' : 'dark';
}

onMounted(async () => {
  displayMode.value = localStorage.displayMode || 'dark'
  darkMode.value = displayMode.value === 'dark' ? true : false
  theme.global.name.value = displayMode.value
  await initialize()
  await handleRedirect()
})
</script>

<style scoped>
.login-name {
  font-style: italic;
}

.dark-mode {
  margin-right: 3em;
}

.main {
  min-height: 300px;
  margin-left: 2em;
  margin-right: 4em;
}
</style>
