<template>
  <v-select v-model="currentGender" :items="genders" :label="label" :readonly="readOnly"
    :menu-icon="readOnly ? '' : '$dropdown'" @update:model-value="genderSelected" />
</template>

<script setup lang="ts">
import { defineProps, ref, withDefaults, onMounted, defineEmits } from 'vue'

const emits = defineEmits(["genderSelected"])

const genders: string[] = ['Female', 'Male'];


interface Props {
  label?: string
  male?: boolean
  readOnly: boolean
}

const props = withDefaults(defineProps<Props>(), {
  label: 'Gender',
  readOnly: false
})

const currentGender = ref<string>()

const selectGender = (male?: boolean): string => {
  if (male === undefined) {
    return ''
  }
  if (male) {
    return genders[1]
  }
  return genders[0]
}

const genderSelected = () => {
  emits("genderSelected", currentGender.value === genders[1])
}

onMounted(() => {
  currentGender.value = selectGender(props.male)
})
</script>

<style lang="scss" scoped></style>
