import { IBackendResponse } from "@/interfaces/IBackendResponse";
import { IDatabaseResponse } from "@/interfaces/IDatabaseResponse";
import { IFilter, IContactsApi } from "@/interfaces/IContactsApi";
import { useAuth } from "@/config/useAuth";

const { login } = useAuth();

export class ContactsApi<T> {
  payload: IContactsApi;
  backendPayload?: IBackendResponse<T>;
  data?: IDatabaseResponse<T>;
  result?: T;

  constructor(p: IContactsApi) {
    this.payload = p;
  }

  set_filter(filter: IFilter[]) {
    this.payload.filter = filter;
  }

  primary_key(): string {
    if (
      this.payload.primary_key !== undefined &&
      this.payload.primary_key !== ""
    ) {
      return `&primary_key=${this.payload.primary_key}`;
    }
    return "";
  }

  value(): string {
    if (this.payload.value !== undefined && this.payload.value !== "") {
      return `&value=${this.payload.value}`;
    }
    return "";
  }

  columns(): string {
    if (this.payload.columns !== undefined && this.payload.columns.length > 0) {
      return `&columns=[${this.payload.columns.map((s) => '"' + s + '"')}]&`;
    }
    return "";
  }
  sort(): string {
    if (this.payload.sort !== undefined && this.payload.sort.length > 0) {
      return `&sort=[${this.payload.sort.map(
        (s) => '{"' + s.key + '":"' + s.order + '"}'
      )}]`;
    }
    return "";
  }
  limit(): string {
    if (this.payload.limit !== undefined && this.payload.limit > 0) {
      return `&limit=${this.payload.limit}`;
    }
    return "";
  }
  offset(): string {
    if (this.payload.offset !== undefined) {
      return `&offset=${this.payload.offset}`;
    }
    return "";
  }
  filter(): string {
    if (this.payload.filter !== undefined && this.payload.filter.length > 0) {
      return `&filter=[${this.payload.filter.map(
        (f) =>
          '{"column":"' +
          f.column +
          '","value":"' +
          f.value +
          '","operator":"' +
          f.operator +
          '"}'
      )}]`;
    }
    return "";
  }
  api(): string {
    return `https://contacts-api.home.okkara.net/${this.payload.command}`;
  }

  encodeURI(): string {
    return encodeURI(
      `${this.api()}?${this.primary_key()}${this.value()}${this.columns()}${this.limit()}${this.offset()}${this.sort()}${this.filter()}`
    );
  }

  async delete(id: string) {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: this.payload.token,
      },
    };
    const resp = await fetch(`${this.api()}/${id}`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        this.backendPayload = json;
        this.data = this.backendPayload?.data;
        if (this.backendPayload?.status === "SUCCESS") {
          this.result = this.data?.result;
        }
      })
      .catch((err) => console.log(err.message));
    return resp;
  }

  async put(body: T, id: string) {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: this.payload.token,
      },
      body: JSON.stringify(body),
    };
    const resp = await fetch(`${this.api()}/${id}`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        this.backendPayload = json;
        this.data = this.backendPayload?.data;
        if (this.backendPayload?.status === "SUCCESS") {
          this.result = this.data?.result;
        }
      })
      .catch((err) => console.log(err.message));
    return resp;
  }

  async post(body: T) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: this.payload.token,
      },
      body: JSON.stringify(body),
    };
    const resp = await fetch(`${this.api()}`, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        this.backendPayload = json;
        this.data = this.backendPayload?.data;
        if (this.backendPayload?.status === "SUCCESS") {
          this.result = this.data?.result;
        }
      })
      .catch((err) => console.log(err.message));
    return resp;
  }

  async fetch() {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: this.payload.token,
      },
    };
    const resp = await fetch(this.encodeURI(), requestOptions)
      .then((res) => res.json())
      .then((json) => {
        this.backendPayload = json;
        this.data = this.backendPayload?.data;
        if (this.backendPayload?.status === "SUCCESS") {
          this.result = this.data?.result;
        } else if (this.backendPayload?.status === "EXPIRED") {
          login();
        }
      })
      .catch((err) => console.log(err.message));
    return resp;
  }
}
