import { IFilter } from "@/interfaces/IContactsApi";

export default class SearchParser {
  searchText: string;
  parsedText: string;
  singlePersonSearch: boolean;
  filter: IFilter[];

  constructor(searchText: string, singlePersonSearch: boolean) {
    console.log("Init Parser: ", searchText);
    this.searchText = searchText;
    this.parsedText = "";
    this.filter = [];
    this.singlePersonSearch = singlePersonSearch;
  }

  encodeURI(): string {
    const encoded = encodeURI(this.parsedText.toLowerCase()).replaceAll(
      "&",
      "%26"
    );
    return encoded;
  }

  error(): string {
    // Search for wildcards
    const wildCardStart = this.searchText.split(":");
    const wildCard = this.searchText.split(":*");
    if (wildCardStart.length !== wildCard.length) {
      return 'To enter a wildcard have to type ":*"';
    }

    // Search for matching parenteces
    const openParenteces = this.searchText.split("(");
    const closeParenteces = this.searchText.split(")");
    if (openParenteces.length !== closeParenteces.length) {
      return "Parentheses mismatch!";
    }
    return (this.searchText.split('"').length - 1) % 2 === 1
      ? 'There is a " missing'
      : "";
  }

  generateFilter(): IFilter[] {
    // Don't do anything if quotes are missing
    if (this.error() || this.searchText.length === 0) return [];

    const searchArray = this.searchText.split('"');
    console.log("Search Array:", searchArray);
    searchArray.forEach((value, index) => {
      if (index % 2) {
        searchArray[index] = value.replaceAll(" ", "\\ ");
      } else {
        const tmpString = value
          .replace(/\s*[|,&,(,),!]\s*/g, (ch) => {
            console.log(`Ch: "${ch}"`);
            return ch.trim();
          })
          .replace(/\s+/g, this.singlePersonSearch ? "&" : "|");
        searchArray[index] = tmpString;
        console.log("Temp: ", tmpString);
        console.log("Replace: ", searchArray);
      }
    });
    this.parsedText = searchArray.join("");
    console.log("Search Text: ", this.parsedText);
    this.filter = [
      {
        column: "textsearch",
        value: this.encodeURI(),
        operator: "@@",
      },
    ];
    console.log("Filter: ", this.filter);
    return this.filter;
  }
}
