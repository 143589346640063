<template>
  <v-checkbox density="compact" hide-details="true" flat="false" single-line="true" :label="label" :model-value="value"
    @click="onChecked"></v-checkbox>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits } from 'vue'

const emits = defineEmits(["onChecked"])

interface Props {
  id: string
  label?: string
  value: boolean
}
const props = defineProps<Props>()

const checked = ref<boolean>(props.value)

const onChecked = () => {
  checked.value = checked.value ? false : true
  emits('onChecked')
}
</script>

<style lang="scss"></style>
