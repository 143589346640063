<template>
  <v-row v-if="!connection.delete">
    <v-text-field label=" " :model-value="connection.connection" @update:model-value="valueUpdated"
      :error-messages="displayError()" :readonly="readOnly">
      <template v-slot:append-inner>
        <div v-if="connection.primary || !readOnly" :class="primaryClass()" @click="selectPrimary">
          primary
        </div>
      </template>
    </v-text-field>
    <div class="connection-label custom-label v-label">
      <v-select label="" :model-value="label()" rounded="false" variant="plain" flat density="compact"
        :loading="loading" single-line :items="locationsExceptCurrent()" :readonly="readOnly"
        :menu-icon="readOnly ? '' : '$dropdown'" :style="readOnly ? 'cursor: default;' : 'cursor: pointer;'"
        @update:model-value="newLabel">
        <template v-slot:item="{ props, item }">
          <v-list-item v-bind="props" :title="item.raw.name" />
        </template>
      </v-select>
    </div>
    <v-btn size="large" rounded="lg" class="ml-2 mt-2" v-if="!readOnly" @click="emits('onDelete', connection)">
      <v-icon color="red" size="x-large">mdi-trash-can</v-icon>
    </v-btn>
  </v-row>
</template>

<script setup lang="ts">
import { ContactsApi } from '@/classes/ContactsApi';
import { IConnectionComponent } from '@/interfaces/IConnectionComponent';
import { IConnectionLocation } from '@/interfaces/IConnectionLocation';
import { onMounted } from 'vue';
import { defineProps, ref, withDefaults, defineEmits } from 'vue'

const emits = defineEmits([
  "onLocationChanged",
  "onValueUpdated",
  "onPrimarySelected",
  "onDelete"])

const locations = ref<IConnectionLocation[]>()

const loading = ref<boolean>(true)
const currentLocation = ref<IConnectionLocation>()

interface Props {
  connection: IConnectionComponent
  readOnly?: boolean
  hasReset?: boolean
  token: string
}


const props = withDefaults(defineProps<Props>(), {
  readOnly: false,
  hasReset: false
})

let contactsapi = new ContactsApi<IConnectionLocation[]>({
  command: 'contacts.get_connection_locations',
  token: props.token,
  sort: [{ key: 'name', order: 'ASC' }]
})


const displayError = (): string => {
  if (!validateInput(props.connection.connection)) {
    switch (props.connection.connection_type.name) {
      case 'Email':
        return "Invalid email address."
      case 'Phone':
        return "Invalid phone number."
      default:
        return "Invalid"
    }
  }
  return ""
}

const valueUpdated = (e: unknown) => emits("onValueUpdated", props.connection, e as string)

const fetchLocation = () => {
  if (!locations.value || locations.value?.length === 0) {
    loading.value = true
    contactsapi.fetch().then(() => {
      locations.value = contactsapi.result
      loading.value = false
    })
  }
}

const locationsExceptCurrent = (): IConnectionLocation[] => {
  fetchLocation()
  return locations.value?.filter((location) => {
    if (location.id !== props.connection.connection_location.id) {
      return location
    }
  }) as IConnectionLocation[]
}

const validateInput = (v: string): boolean => {
  const valid = v.match(props.connection.connection_type.pattern)
  return valid !== null && valid.length > 0 && (valid[0][valid[0].length - 1] !== ' ' && valid[0][valid[0].length - 1] !== '-')
}

const label = (): string => `${currentLocation.value?.name} ${props.connection.connection_type.name}`

const newLabel = (v: unknown) => {
  currentLocation.value = v as IConnectionLocation
  emits('onLocationChanged', props.connection, v as IConnectionLocation)
}

onMounted(() => {
  currentLocation.value = props.connection.connection_location
  loading.value = false
})

const primaryClass = (): string => {
  let classes: string[] = []
  if (props.connection.primary) {
    classes.push("primary")
  } else {
    classes.push("not-primary")
  }
  if (props.readOnly) {
    classes.push("modify")
  }
  return classes.join(" ")
}

const selectPrimary = () => {
  if (!props.connection.primary) {
    emits('onPrimarySelected', props.connection)
  }

}
</script>

<style lang="scss">
.connection-label {
  position: absolute;
  margin-top: 10px;
  padding-left: 1em;
  width: 8em;
  height: 1.6em;
  background-color: rgba(0, 0, 0, 0.0);

  .v-select__selection-text {
    font-size: 12px;
  }

}

.primary,
.not-primary {
  padding: 4px;
  color: rgba(var(--v-theme-surface-variant));
  border: 1px solid rgba(var(--v-theme-surface-variant));
}

.not-primary {
  border: 1px solid rgba(var(--v-theme-on-surface-variant));
  color: rgba(var(--v-theme-on-surface-variant));
  transition: 0.2s;

  &:hover {
    border: 2px solid rgba(var(--v-theme-surface-variant));
    color: rgba(var(--v-theme-surface-variant));
    cursor: pointer;
  }
}

.modify {
  cursor: pointer;
}
</style>
