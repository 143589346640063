<template>
  <v-row align="center">
    <v-col align="center">
      <v-progress-circular v-if="loading" indeterminate model-value="20" :size="128" :width="9"></v-progress-circular>
      <v-card v-if="!loading">
        <v-card-title :align="memberInfo === undefined ? 'center' : 'left'">
          <div v-if="memberInfo !== undefined">
            Member Id: {{ memberInfo.data.current_member_id }}
          </div>
          <div v-else>
            Is not a member!
          </div>
        </v-card-title>
        <v-card-text v-if="memberInfo !== undefined">
          <v-text-field v-if="memberInfo.data.date_of_baptism || !readonly" label="Baptised"
            v-model="memberInfo.data.date_of_baptism" :readonly="readonly" />
          <MemberShips :current_member_id="memberInfo.data.current_member_id" :memberships="memberInfo.memberships"
            :token="token" :readonly="readonly" />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { ref, defineProps, onMounted } from 'vue'
import { ContactsApi } from '@/classes/ContactsApi';
import { IMemberInfo } from '@/interfaces/IMemberInfo'
import MemberShips from '@/components/MemberShips.vue'


interface Props {
  person_id: number
  readonly: boolean
  token: string
}

const loading = ref<boolean>(true)

const props = defineProps<Props>()

const memberInfo = ref<IMemberInfo>()

let members_info = new ContactsApi<IMemberInfo>({
  command: 'members.get_info',
  token: props.token,
  primary_key: "person_id",
  value: props.person_id.toString()
})

onMounted(() => {
  members_info.fetch().then(() => {
    memberInfo.value = members_info.result
    loading.value = false
  })

  loading.value = true
})

</script>
