<template>
  <div>
    <NewConnection :token="token" v-if="!readOnly" @on-add="onAdd" />
    <v-col sm="8">
      <CustomConnection :token="token" v-for="connection in connections" :key="connection.id" :connection="connection"
        @on-value-updated="valueChanged" @on-location-changed="changedLocation" @on-delete="onDelete(connection)"
        @on-primary-selected="primarySelected" :read-only="readOnly" />
    </v-col>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, withDefaults } from 'vue'
import { IConnectionComponent } from '@/interfaces/IConnectionComponent'
import { IConnectionType } from '@/interfaces/IConnectionType'
import CustomConnection from '@/components/CustomConnection.vue'
import { IConnectionLocation } from '@/interfaces/IConnectionLocation'
import NewConnection from '@/components/NewConnection.vue'

const emits = defineEmits(["newConnection", "onDelete"])

interface Props {
  readOnly?: boolean
  connections?: IConnectionComponent[]
  token: string
}

const props = withDefaults(defineProps<Props>(), {
  readOnly: false
})

const primarySelected = (connection: IConnectionComponent) => {
  console.log("Toggle Primary: ", connection)
  connection.primary = connection.primary ? false : true

  if (connection.primary) {
    props.connections?.forEach((c) => {
      if (c.connection_type.id === connection.connection_type.id && c !== connection) {
        c.primary = false
      }
    })
  }
}

const onDelete = (connection: IConnectionComponent) => {
  connection.delete = true
}

const changedLocation = (connection: IConnectionComponent, location: IConnectionLocation) => {
  connection.connection_location = location
}

const valueChanged = (currentConnection: IConnectionComponent, newValue: string) => {
  currentConnection.connection = newValue
}
const onAdd = (ct: IConnectionType) => {
  console.log("Add")
  emits('newConnection', ct)
}
</script>

<style lang="scss"></style>
