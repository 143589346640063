<template>
  <v-text-field prepend-inner-icon="mdi-magnify" clearable @click:clear="onReset" :label="label" :v-model="value"
    @input="onInput" :error-messages="error" />
</template>

<script setup lang="ts">
import { defineProps, defineEmits, withDefaults } from 'vue'

const emits = defineEmits(["onInput", "onReset"])

interface Props {
  label: string
  value?: string
  error?: string
  hasReset?: boolean
  readOnly?: boolean
}

withDefaults(defineProps<Props>(), {
  label: '',
  error: '',
  hasReset: false,
  readOnly: false
})

const onInput = (e: Event) => {
  emits('onInput', (e.target as HTMLInputElement).value)
}

function onReset() {
  emits('onReset');
}
</script>

<style lang="scss" scoped></style>
