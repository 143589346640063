<template>
  <v-combobox v-model="streetname" @update:search="onInput" item-value="address" :items=suggestions item-title="street"
    :menu-icon="readOnly ? '' : '$dropdown'" :label="label" :loading="loading" :hide-selected="false"
    :auto-select-first="false" :readonly="readOnly" return-object @update:model-value="valueUpdated">
    <template v-slot:item="{ props, item }">
      <v-list-item v-bind="props" :title="item.raw.street" :subtitle="subtitle(item.raw)" />
    </template>
  </v-combobox>
</template>

<script setup lang="ts">
import { IAddress } from '@/interfaces/IAddress';
import { defineProps, withDefaults, defineEmits, ref, onMounted } from 'vue'
import { ContactsApi } from '@/classes/ContactsApi';
import { IFilter } from '@/interfaces/IGetApi';

const emits = defineEmits(["onValueUpdated"])

const suggestions = ref<IAddress[]>()
const loading = ref<boolean>(false)

const streetname = ref<string>("")

interface Props {
  label: string,
  address?: IAddress,
  readOnly: boolean
  token: string
}

const props = withDefaults(defineProps<Props>(), {
  label: 'Street',
  readOnly: true
})

let Addresses = new ContactsApi<IAddress[]>({
  command: 'contacts.get_addresses',
  token: props.token,
  sort: [{ key: 'street', order: 'ASC' }],
  limit: 10
})

const subtitle = (address: IAddress): string => {
  const r = address.residents.map(r => `${r.firstname} ${r.lastname}`)
  const residents = r.length > 0 ? `(${r.join(' - ')})` : ''
  return `${address?.city?.zipcode} ${address?.city?.name} ${residents}`
}

const onInput = (value: string) => {
  loading.value = true
  if (value.length > 0) {
    let filter: IFilter[] = [{
      column: 'street',
      operator: 'ilike',
      value: value + '%'
    }]
    Addresses.set_filter(filter)
    Addresses.fetch().then(() => {
      suggestions.value = Addresses.result
      loading.value = false
    })
  } else {
    suggestions.value = []
    loading.value = false
  }
}

const valueUpdated = (street: unknown) => {
  if (typeof street === "string") {
    emits('onValueUpdated', { street: street } as IAddress)
  } else {
    emits('onValueUpdated', street as IAddress)
  }
}

onMounted(() => {
  if (props.address?.id !== undefined) {
    streetname.value = props.address.street
  }
}) 
</script>

<style lang="scss" scoped></style>
