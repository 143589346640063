import { IAddress } from "@/interfaces/IAddress";
import { IConnection } from "@/interfaces/IConnection";
import { IPerson } from "@/interfaces/IPerson";
import { IPersonTags } from "@/interfaces/IPersonTags";
import { ContactsApi } from "./ContactsApi";

export default class Person implements IPerson {
  id: number;
  firstname: string;
  middlename?: string;
  lastname?: string;
  male?: boolean;
  day_of_birth?: Date;
  address?: IAddress;
  connections?: IConnection[];
  tags?: IPersonTags;
  created_at?: Date;
  updated_at?: Date;
  is_saved: boolean;
  token: string;

  constructor(person: IPerson, token: string) {
    this.id = person.id;
    this.firstname = person.firstname;
    this.middlename = person.middlename;
    this.lastname = person.lastname;
    this.male = person.male;
    this.day_of_birth = person.day_of_birth;
    this.address = person.address;
    this.connections = person.connections;
    this.tags = person.tags;
    this.created_at = person.created_at;
    this.updated_at = person.updated_at;
    this.is_saved = false;
    this.token = token;
  }

  async Save() {
    if (!this.id || this.id === 0) {
      await this.Add();
    } else {
      await this.Update();
    }
  }

  async Delete() {
    console.log("Person Delete Id: ", this.id);
    const person = new ContactsApi<IPerson>({
      command: "contacts.delete_person",
      token: this.token,
      primary_key: "id",
      value: this.id.toString(),
    });
    await person.delete(this.id.toString()).then((resp) => {
      console.log("Delete Response: ", resp);
      this.is_saved = true;
    });
  }

  async Add() {
    console.log("Add: ", this);
    const person = new ContactsApi<IPerson>({
      command: "contacts.add_person",
      token: this.token,
    });
    await person.post(this).then((resp) => {
      console.log("Update Response: ", resp);
      this.is_saved = true;
    });
  }

  async Update() {
    const person = new ContactsApi<IPerson>({
      command: "contacts.update_person",
      token: this.token,
      primary_key: "id",
      value: this.id.toString(),
    });
    console.log("Update: ", this);
    await person.put(this, this.id.toString()).then((resp) => {
      console.log("Update Response: ", resp);
      this.is_saved = true;
    });
  }
}
